import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('@app/pages/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'services',
    loadChildren: () => import('@app/pages/services/services.module').then(m => m.ServicesModule)
  },
  {
    path: 'json',
    loadChildren: () => import('@app/pages/json/json.module').then(m => m.JsonModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('@app/pages/contact/contact.module').then(m => m.ContactModule)
  },
  {
    path: 'about',
    loadChildren: () => import('@app/pages/about/about.module').then(m => m.AboutModule)
  },
  { path: '**', redirectTo: 'home', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
