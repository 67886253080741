<div class="mobile-nav">

  <nav>
    <ul>
      <li><a routerLink="/home">HOME </a></li>
      <li><a routerLink="/about">ABOUT</a></li>
      <li><a routerLink="/services">SERVICES</a></li>
      <li><a routerLink="/json">JsonAutoService</a></li>
      <li><a routerLink="/contact">CONTACT</a></li>
    </ul>
  </nav>

  <div class="menu-bottom">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <ul class="social-icon">
            <li><a href="" target="_blank"><i class="fa fa-facebook-f"></i></a></li>
            <li><a href="" target="_blank"><i class="fa fa-twitter"></i></a></li>
            <li><a href="" target="_blank"><i class="fa fa-linkedin"></i></a></li>
            <li><a href="" target="_blank"><i class="fa fa-youtube-play"></i></a></li>
          </ul>
        </div>
        <div class="col-md-6 col-sm-12">
          <p>
            Copyright 2020 <b>©Relatable Technologies </b> </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mobile-nav-btn">
  <span class="lines"></span>
</div>

<header>
  <div class="container">
    <div class="row">
      <div class="col-md-3 col-sm-12">
        <a routerLink="/home" class="logo"><img src="assets/img/logo.png" alt="image"/></a>
      </div>
      <div class="col-md-9 col-sm-12">
        <nav>
          <ul>
            <li><a routerLink="/home" routerLinkActive="active" >Home </a></li>
            <li><a routerLink="/about" routerLinkActive="active">About</a></li>
            <li><a routerLink="/services" routerLinkActive="active">Services</a></li>
            <li><a routerLink="/json" routerLinkActive="active">JsonAutoService</a></li>
            <li><a routerLink="/contact" routerLinkActive="active">Contact</a></li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</header>
