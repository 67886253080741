<section class="SecHomeBottom">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 col-sm-12 img-left">
        <img src="assets/img/bottomimg.png" class="icon img-fluid" alt="image">
      </div>
      <div class="col-md-5 col-sm-12 d-flex align-items-center padleft">
        <div class="bottom-cont">
          <h2>Let’s build better solutions together. Please contact us today.
          </h2>
          <a routerLink="/contact">LET’S DISCUSS</a>
        </div>
      </div>
    </div>
  </div>
</section>

<footer>
  <div class="footer-top">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <a href="#"> <img src="assets/img/footer-logo.png" alt="image"> </a>
        </div>
        <div class="col-md-2">
          <h4>Quick Links </h4>
          <nav>
            <ul>
              <li><a routerLink="/home">HOME </a></li>
              <li><a routerLink="/about">ABOUT</a></li>
              <li><a routerLink="/services">SERVICES</a></li>
              <li><a routerLink="/json">JsonAutoService</a></li>
              <li><a routerLink="/contact">CONTACT</a></li>
            </ul>
          </nav>
        </div>

        <div class="col-md-7 ser-links">
          <h4> Services </h4>
          <nav>
            <ul>
              <li><a routerLink="/services"> API Development </a></li>
              <li><a routerLink="/services"> Beyond Excel </a></li>
              <li><a routerLink="/services">Cloud Migration & Entry </a></li>
              <li><a routerLink="/services"> .NET Framework to .NET Core </a></li>
              <li><a routerLink="/services">Database Architecture & Development </a></li>
              <li><a routerLink="/services">Ecommerce Integration </a></li>
              <li><a routerLink="/services">Voice Applications </a></li>
              <li><a routerLink="/services">3rd Party API Integration </a></li>
              <li><a routerLink="/services">Identity & Access Management </a></li>
              <li><a routerLink="/services">Rapid Application Development </a></li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-bottom">
    <div class="container">
      <div class="row">

        <div class="col-md-6 col-sm-12">
          <p>
            Copyright 2020 <b>©Relatable Technologies,</b> all rights reserved. Terms and conditions.  </p>
        </div>

        <div class="col-md-6 col-sm-12">
          <ul class="social-icon">
            <li><a href="" target="_blank"><i class="fa fa-facebook-f"></i></a></li>
            <li><a href="" target="_blank"><i class="fa fa-twitter"></i></a></li>
            <li><a href="" target="_blank"><i class="fa fa-linkedin"></i></a></li>
            <li><a href="" target="_blank"><i class="fa fa-youtube-play"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>
